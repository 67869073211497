.chat {
    flex:0.7;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #e5ddd5;
    transform: translateZ(456px);
}

.page-enter {
    opacity: 0;
    transform: scale(0.9) translateZ(456px);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1) translateZ(456px);
    transition: transform 300ms, opacity 300ms; 
}

.page-exit {
    opacity: 1;
    transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    transform: scale(0.9) translateZ(456px);
    transition: transform 300ms, opacity 300ms;
}

.chat__background {
    background-image: url("https://web.whatsapp.com/img/bg-chat-tile-light_686b98c9fdffef3f63127759e2057750.png");
    background-size: contain;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.06;
}

.chat__header {
    padding: 10px;
    padding-right: 0px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    background-color: #ededed;
    z-index: 1;
}

.chat__header--info {
    flex: 1;
    margin-left: 8px;
}

.chat__header--info > h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
}

.chat__header--info > p {
    color: grey;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
    font-size: 13px;
}

.chat__header--right {
    display: flex;
    justify-content: space-between;
    min-width: 50px;
}

.chat__header--right > .MuiButtonBase-root {
    padding: 12px;
}

.chat__body--container {
    flex: 1;
    background-repeat: repeat;
    background-position: center;
    overflow: scroll;
    background-color: transparent;
    z-index: 1;
    position: relative;
}

.chat__body {
    opacity: 0;
    background-color: transparent;
    padding: 0px 60px;
    padding-bottom: 98px !important;
    transition: opacity 200ms; 
    position: relative;
}

.chat__body--container::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

.chat__body > .chat__message:last-child {
    opacity: 0;
    transition: opacity 150ms;
}

.paginateLoader {
    background: transparent !important;
    /*transition: height 200ms;*/
}

.paginateLoader > div > .MuiCircularProgress-svg {
    transform: scale(1);
    /*display: none;*/
}

.chat__message {
    z-index: 0;
    position: relative;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    padding-top: 22px;
    word-wrap: break-word;
}

.chat__message--message {
    margin-right: 10px;
}

.chat__reciever {
    margin-left: auto;
    background-color: #dcf8c6;
}

.chat__lastMessage {
    margin-bottom: 0;
    opacity: 0;
    animation: fade-in 300ms forwards 600ms;
}

.chat__name {
    position: absolute;
    top: 8px;
    left: 9px;
    font-weight: 500;
    font-size: 10.5px;
    color: grey;
}

.image-container {
    position: relative;
    max-height: 300px;
    max-width: 300px;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 10px;
}

.image-container img {
    /*object-fit: cover;*/
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.chat__message img {
    cursor: pointer
}

.image__container--loader {
    transform:  translateX(-50%) translateY(-50%);
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.image-container .MuiCircularProgress-svg {
    transform: none !important;
}

.chat__timestamp {
    font-size: 10px;
    text-align: right;
    color: grey;
}

.scroll {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    left: 50%;
    bottom: 78px;
    transform: translateX(-50%) scale(1);
    transition: transform 150ms;
    z-index: 1;
    box-shadow: 0px 0px 2px 0px #00000087;
    cursor: pointer;
}

.scroll svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.scroll div {
    position: absolute;
    top: 0;
    right:0;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #06D755;
    color: white;
    font-size: 10px;
    font-weight: 500;
    display: grid;
    place-items: center;
    color: white;
}

.scroll-enter {
    transform: translateX(-50%) scale(0.7);
    opacity: 0;
}

.scroll-enter-active {
    opacity: 1;
    transform: translateX(-50%) scale(1);
    transition: opacity 300ms, transform 300ms;
}

.scroll-exit {
    opacity: 1;
    transform: translateX(-50%) scale(1);
}

.scroll-exit-active {
    opacity: 0;
    transform: translateX(-50%) scale(0.7);
    transition: opacity 300ms, transform 300ms;
}

.chat__deleting {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: #ffffff6c;
    z-index: 2;
}

.seen {
    position: absolute;
    height: 30px;
    width: 94px;
    right: 60px;
    bottom: 58px;
    opacity: 0 !important;
}

.seen-animate-enter {
    opacity: 0 !important;
}

.seen-animate-enter-active {
    opacity: 1 !important;
    transition: opacity 200ms;
}

.seen-animate-exit {
    opacity: 0 !important;
}

.seen-animate-exit-active {
    opacity: 0 !important;
}

.seen-animate-enter-done {
    opacity: 1 !important;
}

.seen p {
    background-color: #dcf8c6;
    padding: 3px 15px;
    text-align: center;
    position: absolute;
    right: 0;
    border-radius: 10px;
}

.seen span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.seen .MuiSvgIcon-root {
    transform: scale(0.9);
    margin-left: 5px;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (min-width: 761px) {
    .chat__header--info > h3 {
        width: 350px;
    }

    .chat__header--info > p {
        width: 350px;
    }
}

@media (max-width: 760px) {
    .seen {
        right: 20px;
    }
    .chat {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #e5ddd5;
        opacity: 0;
        transform: scale(0.9);
        transition: transform 250ms cubic-bezier(0.37, 0, 0.63, 1), opacity 250ms cubic-bezier(0.37, 0, 0.63, 1);
    }

    .chat__body {
        padding:0px 20px;
    }

    .image-container .MuiCircularProgress-svg {
        transform: scale(1.5) !important;
    }
    .chat__header {
        padding: 10px;
        padding-left: 8px;
    }
    .chat__header--right > .MuiButtonBase-root {
        padding: 8px;
    }
    .chat__header > .MuiButtonBase-root {
        padding: 0px;
        padding-right: 5px;
        border-radius: 18.5px
    }
    
    .chat__header--info > p, .chat__header--info > h3 {
        width: 180px;
    }
    .chat__header--info > p {
        font-size: 11px;
        font-weight: 400;
    }

    .page-enter-active {
        opacity: 1;
        transform: scale(1);
        transition: transform 300ms cubic-bezier(0.37, 0, 0.63, 1), opacity 300ms cubic-bezier(0.37, 0, 0.63, 1); 
    }

    .page-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: transform 300ms cubic-bezier(0.37, 0, 0.63, 1), opacity 300ms cubic-bezier(0.37, 0, 0.63, 1);
    }
}

.chat-animate {
    opacity: 1;
    transform: scale(1);
}