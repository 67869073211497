.imagePreview {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 15;
    transition: all 200ms;
    background-color: transparent;
    transform: translateZ(456px);
}

.imagePreview .MuiSvgIcon-root {
    height: 35px;
    width: 35px;
    position: absolute;
    padding: 5px;
    top: 10px;
    right: 10px;
    z-index: 2;
    background: #f5f5f5;
    border: none;
    border-radius: 17.5px;
    cursor: pointer;
    transform: scale(0.9);
    opacity: 0;
    transition: transform 200ms, opacity 200ms;
}

.imagePreview .close-animate {
    transform: scale(1);
    opacity: 1;
}

.imagePreview .imagePreview-container {
    z-index: 1;
    margin: 0;
    position: absolute;
    overflow: hidden;
    border-radius: 10px;
    opacity: 1;
    transform: translateX(0%) translateY(0%);
    transition: height 300ms cubic-bezier(0.37, 0, 0.63, 1), transform 300ms cubic-bezier(0.37, 0, 0.63, 1), border-radius 300ms cubic-bezier(0.37, 0, 0.63, 1);
}

.imagePreview img {
	max-width: 100%;
    max-height: 100vh;
    position: absolute;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}